<script>
import { ArrowUpIcon, HeartIcon, EyeIcon, ShoppingCartIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import Course from "@/view/front/components/course";
import API from "@/core/api";
import IndexService from "@/MainServices/IndexService.js";

/**
 * Shop-product-detail component
 */
export default {
  data() {
    return {
      countval: 1,
      API: API.API_HOME,
      discipline_name: this.$route.params.discipline_name,
      courses: [],
      loading: true,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
  },
  methods: {},
  mounted() {
    IndexService.getDisciplineCourse(this.discipline_name).then((response) => {
      this.courses = response.data;
      this.loading = false;
    });
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-custom bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Universities Plan</h4>
              <div class="page-next"></div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->

    <section class="section">
      <div class="container">
        <div class="row align-items-center">
          <div class="comparison">
            <p
              class="product tl tl2 custome-title-price-table"
              style="
                border-top-left-radius: 5px;
                border-left: 0px;
                border-top-right-radius: 5px;
                background: rgb(105, 199, 241);
                margin: 0px;
                border-bottom: 1px solid;
                padding-bottom: 6%;
              "
            >
              Choose the plan to your needs
            </p>
            <table>
              <thead>
                <tr>
                  <th class="tl tl2"></th>
                  <th class="product" style="background: #69c7f1; border-left: 0px">
                    Bronze
                  </th>
                  <th class="product" style="background: #69c7f1">Silver</th>
                  <th class="product" style="border-right: 0px; background: #69c7f1">
                    Gold
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th class="price-info">
                    <div class="price-now">
                      <span>$ 1.09</span>
                      <p>  / year</p>
                    </div>
                  </th>
                  <th class="price-info">
                    <div class="price-now">
                      <span>$ 1.79</span>
                      <p>  /month</p>
                    </div>
                  </th>
                  <th class="price-info">
                    <div class="price-now">
                      <span>$ 2.49</span>
                      <p> /month</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td colspan="3">Featured</td>
                </tr>
                <tr class="compare-row">
                  <td>Featured</td>
                  <td><span>–</span></td>

                  <td><span>–</span></td>

                  <td><i class="fa fa-check"></i></td>
                </tr>
                <tr>
                  <td> </td>
                  <td colspan="3">Categories</td>
                </tr>
                <tr>
                  <td>Categories</td>
                  <td><span>2</span></td>
                  <td><span>5</span></td>
                  <td><span>10</span></td>
                </tr>
                <tr>
                  <td> </td>
                  <td colspan="3">Images</td>
                </tr>
                <tr class="compare-row">
                  <td>Images</td>
                  <td><span>1</span></td>
                  <td><span>3</span></td>
                  <td><span>5</span></td>
                </tr>
                <tr>
                  <td> </td>
                  <td colspan="4">Video Link</td>
                </tr>
                <tr>
                  <td>Video Link</td>
                  <td><span>–</span></td>

                  <td><span>–</span></td>

                  <td><i class="fa fa-check"></i></td>
                </tr>
                <tr>
                  <td> </td>
                  <td colspan="3">Contact Form</td>
                </tr>
                <tr class="compare-row">
                  <td>Contact Form</td>
                  <td><i class="fa fa-check"></i></td>

                  <td><i class="fa fa-check"></i></td>

                  <td><i class="fa fa-check"></i></td>
                </tr>
                <tr>
                  <td> </td>
                  <td colspan="4">See Views</td>
                </tr>
                <tr>
                  <td>See Views</td>
                  <td><span>–</span></td>

                  <td><span>–</span></td>

                  <td><i class="fa fa-check"></i></td>
                </tr>
                <tr>
                  <td> </td>
                  <td colspan="3">Online Application</td>
                </tr>
                <tr class="compare-row">
                  <td>Online Application</td>
                  <td><span>–</span></td>

                  <td><span>–</span></td>

                  <td><i class="fa fa-check"></i></td>
                </tr>

                <tr>
                  <td> </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <a
                      href="https://idc.wis.com.tw/contactUs?service=o365-email-security-solution"
                      class="price-buy"
                      >Login<span class="hide-mobile"></span
                    ></a>
                  </td>
                  <td>
                    <a
                      href="https://idc.wis.com.tw/contactUs?service=o365-email-security-solution"
                      class="price-buy"
                      >Login<span class="hide-mobile"></span
                    ></a>
                  </td>
                  <td>
                    <a
                      href="https://idc.wis.com.tw/contactUs?service=o365-email-security-solution"
                      class="price-buy"
                      >Login<span class="hide-mobile"></span
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.custome-title-price-table {
  margin-left: 11.6rem !important;
}
.comparison {
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 30px;
  font: 13px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 10px;
}

.comparison table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
}

.comparison td,
.comparison th {
  border-right: 1px solid #e8e8e8;
  empty-cells: show;
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  color: #808080;
  font-size: 15px;
}

.comparison tbody tr:nth-child(odd) {
  display: none;
}

.comparison .compare-row {
  background: #f7f7f7;
}

.fa-check {
  color: #69c7f1;
}

.comparison th {
  font-weight: normal;
  padding: 0;
  border-top: 0px solid #e8e8e8;
  border-bottom: 0px solid #e8e8e8;
}

.comparison tr td:first-child {
  text-align: left;
  padding: 15px;
  border-left: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
}

.comparison .product,
.comparison .tl {
  color: #fff;
  padding: 10px;
  font-size: 14px;
}

.comparison .tl2 {
  border-right: 0;
}

.comparison .product {
  background: #69c7f1;
  height: 40px;
  font-size: 1.6em;
}

.comparison .price-info {
  padding: 15px;
}

.comparison .price-now,
.comparison .price-now span {
  color: #808080;
}

.comparison .price-now span {
  font-size: 36px;
  color: #545454;
}

.comparison .price-now p {
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  display: inline;
}

.comparison .price-buy {
  background: #ec671a;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 5px 0 10px 0;
  letter-spacing: 1px;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.comparison .price-buy:hover {
  background: #e84d00;
}

@media screen and (min-width: 721px) and (max-width: 1000px) {
  .custome-title-price-table {
    margin-left: 12.6rem !important;
  }
  .table_ul li {
    letter-spacing: 0px;
  }
  .comparison .price-now span {
    font-size: 32px;
  }
  .comparison .price-now p {
    display: block;
  }
}

@media screen and (max-width: 720px) {
  .custome-title-price-table {
    margin-left: 0px !important;
  }
  .table_ul {
    padding: 0px;
  }
  .table_ul li {
    font-size: 10px;
    line-height: 16px;
    padding: 3px 0;
  }
  .comparison {
    max-width: 100%;
  }
  .comparison td:first-child,
  .comparison th:first-child {
    display: none;
  }
  .comparison tbody tr:nth-child(odd) {
    display: table-row;
    background: #f7f7f7;
  }
  .comparison .row {
    background: #fff;
  }
  .comparison td,
  .comparison th {
    border: 1px solid #ccc;
    border-top: none;
    padding: 10px;
  }
  .price-info {
    border-top: 0 !important;
    padding: 10px 0 !important;
  }
  .price-info p {
    line-height: 8px;
    font-size: 8px !important;
  }
  .comparison .compare-row {
    background: #ffffff;
  }
  .comparison .price-now p {
    display: block;
  }
  .comparison .price-now span {
    font-size: 24px;
  }
  .comparison .qbse {
    font-size: 1.2em;
  }
  .comparison td {
    font-size: 14px;
  }
  .comparison th {
    font-size: 14px;
  }
}
</style>
